<template>
  <div class="flight_box_one">
    <div class="row">
      <div class="col-lg-4">
        <div class="left_box">
          <div class="flight_icon">
            <img :src="`${speedSizeDomain}/assets/img/img10.png`" alt="img10" title="" :class="{ 'return-air': type === 'return' }" width="64" height="64"/>
          </div>
          <h3 class="text-center" v-if="type === 'depature'">{{ $t("flight-only.round-trip-departure") }}</h3>
          <h3 class="text-center" v-if="type === 'return'">{{ $t("flight-only.round-trip-return") }}</h3>
          <div class="d-flex">
            <div class="img_box m-auto">
              <img v-if="!data || !data.airlineNotConfirmed" :src="markAirline" alt="air-line" title="" width="85" height="85"/>
            </div>
          </div>
          <div class="clearfix"></div>
          <h4 class="text-center mt-2" >....{{ airlineName }}</h4>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="right_box">
          <div class="right_box_one" v-if="type === 'depature'">
            <div class="d-flex">
              <h3>
                {{ $t("search-result.from") }}{{lang !== 'he' ? " " : ""}}{{ fromDestName }}
              </h3>
              <span class="icon_derection"><i class="fas fa-long-arrow-alt-left"></i></span>
              <h3>
                <!-- {{ $t("search-result.to") }}{{lang !== 'he' ? " " : ""}}{{ toDestName }}{{` (${toRoute})` }} -->
                {{ $t("search-result.to") }}{{lang !== 'he' ? " " : ""}}{{ toDestName }}
              </h3>
            </div>
          </div>
          <div class="right_box_one" v-else-if="type === 'return'">
            <div class="d-flex">
              <h3>
                {{ $t("search-result.from") }}{{lang !== 'he' ? " " : ""}}{{ fromDestName }}
              </h3>
              <span class="icon_derection"><i class="fas fa-long-arrow-alt-left"></i></span>
              <h3>
                {{ $t("search-result.to") }}{{lang !== 'he' ? " " : ""}}{{ toDestName }}
              </h3>
            </div>
          </div>
          <div class="right_box_two">
            <h5 v-if="type === 'depature'">
              {{ $t("flight-only.departure-message", {time: departHour, date: departDate, day: departWeek, airport: fromAirportName, terminal: stringTerminal(fromTerminal)}) }}
            </h5>
            <h5 v-else>
              {{ $t("flight-only.departure-message", {time: departHour, date: departDate, day: departWeek, airport: fromAirportName, terminal: stringTerminal(fromTerminal)}) }}
            </h5>
            <!-- <h6>
              {{ $t("flight-only.flight-time-includes") }} {{ data.time.split(":")[0] }}
              {{ $t("search-result.hours") }} {{ $t("flight-only.and") }}
              {{ data.time.split(":")[1] }} {{ $t("search-result.minutes") }}
            </h6> -->
            <h5 v-if="type === 'depature'">
              {{ $t("flight-only.landing-message", {time: arriveHour, date: arriveDate, day: arriveWeek, destination: toDestName, airport: arriveAirportName, terminal: stringTerminal(arriveTerminal)}) }}
            </h5>
            <h5 v-else>
              {{ $t("flight-only.landing-message", { time: arriveHour, date: arriveDate, day: arriveWeek, destination: toDestName, airport: arriveAirportName, terminal: stringTerminal(arriveTerminal)}) }}
            </h5>
          </div>
          <button class="btn-flight">
            <span v-if="data && data.Authorization_msg.includes('לא אושרו')">{{ $t("flight-only.flight-no") }}: N/A</span>
            <span v-else>{{ $t("flight-only.flight-no") }}: {{ airlineNumber }}</span>
          </button>
        </div>
      </div>
      <div class="col-lg-12 baggage">
        <h3>{{ $t("flight-only.baggage-details-title") }}</h3>
        <div class="flight_box_baggage_body">
          <div class="d-flex justify-content-between">
            <div class="flight_box_one_div w-100">
              <span class="img_icon"><img :src="`${speedSizeDomain}/assets/img/img11.png`" alt="img11" title="" width="21" height="18"/></span>
              <h5>
                <!-- {{ $t("flight-only.luggage-plane-desc") }} -->
                <!-- <span>{{ $t("flight-only.luggage-plane-desc") }}
                  {{ $t("flight-only.no-extra-charge") }}, {{ $t("flight-only.max") }}:
                  {{ data.FL_Max_Weight }}Kg
                </span> -->
                <span>{{$t("flight-only.luggage-description", {weight: weightLuggage})}}</span>
              </h5>
            </div>
            <div class="flight_box_one_div w-100">
              <span class="img_icon"><img :src="`${speedSizeDomain}/assets/img/img12.png`" alt="img12" title="" width="16" height="26"/></span>
              <h5>
                <!-- {{ $t("flight-only.including-small-desc") }} -->
                <!-- <span>
                  {{ $t("flight-only.to-board-plane") }} ,
                  {{ $t("flight-only.no-extra-charge") }}, {{ $t("flight-only.max") }}:
                  {{ data.FL_Max_Weight_Hand }}Kg
                </span> -->
                <span>{{$t("flight-only.bag-description", {weight: weightBag})}}</span>
              </h5>
            </div>
          </div>
        </div>
        <!-- <div class="flight_tag_box">
          <h3>
            <span class="icon_check"><i class="fas fa-check-circle"></i></span>{{ authorMessage }}
          </h3>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [gMixin, imageUrlMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    airInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      markAirline: `${this.speedSizeDomain}/assets/img/flying.png`,
    };
  },
  computed: {
    ...mapGetters({
      category: 'GET_CURRENT_CATEGORY',
      lang: 'GET_LANGUAGE',
      airportList: 'GET_AIRPORT',
    }),
    airlineName() {
      return this.data?.arl_Name ?? '';
    },
    fromRoute() {
      return this.data?.FL_From_Route ?? '';
    },
    fromDestName() {
      return this.data?.translations?.flightDestinationName?.[this.fromRoute]?.[this.lang] ?? this.fromRoute;
    },
    departHour() {
      return this.data?.FL_Dep_Hour ?? '';
    },
    departDate() {
      return this.data ? this.getDateFormat(this.data?.FL_Date) : '';
    },
    departWeek() {
      return this.data ? this.getWeek(this.data?.FL_Date) : '';
    },
    fromAirportName() {
      const currAirport = this.airportList.find((air) => air.code === this.data?.FL_From_Air_Port);
      const langName = `name${this.capitalizeFirstLetter(this.lang)}`;
      return currAirport?.[langName] ?? this.data?.FL_From_Air_Port;
    },

    toRoute() {
      return this.data?.FL_To_Route ?? '';
    },
    toDestName() {
      return this.data?.translations?.flightDestinationName?.[this.toRoute]?.[this.lang] ?? this.toRoute;
    },
    arriveHour() {
      return this.data?.FL_Arrv_Hour ?? '';
    },
    arriveDate() {
      return this.data ? this.getDateFormat(this.data?.landingDate) : '';
    },
    arriveWeek() {
      return this.data ? this.getWeek(this.data?.landingDate) : '';
    },
    arriveAirportName() {
      const currAirport = this.airportList.find((air) => air.code === this.data?.FL_To_Air_Port);
      const langName = `name${this.capitalizeFirstLetter(this.lang)}`;
      return currAirport?.[langName] ?? this.data?.FL_To_Air_Port;
    },

    airlineNumber() {
      return this.data ? `${this.data.FL_AIRLINE} - ${this.data.FL_Flt_Number}` : '';
    },
    authorMessage() {
      return this.data ? this.data.Authorization_msg : '';
    },

    fromTerminal() {
      return this.data?.FL_From_Terminal || 'N/A';
    },
    arriveTerminal() {
      return this.data?.FL_To_Terminal || 'N/A';
    },
    weightLuggage() {
      return this.data?.FL_Max_Weight || 20;
    },
    weightBag() {
      return this.data?.FL_Max_Weight_Hand || 5;
    },
  },
  watch: {
    airInfo() {
      if (!!this.airInfo && this.airInfo.desc2 !== '' && this.airInfo.desc2 !== undefined) {
        this.markAirline = this.airInfo.desc2 || `${this.speedSizeDomain}/assets/img/flying.png`;
      }
    },
  },
  beforeMount() {
  },
  created() {
    if (!!this.airInfo && this.airInfo.desc2 !== '' && this.airInfo.desc2 !== undefined) {
      this.markAirline = this.airInfo.desc2 || `${this.speedSizeDomain}/assets/img/flying.png`;
    }
  },
  methods: {
    getDateFormat(str) {
      return dayjs(str).format('DD.MM.YY');
    },
    getWeek(str) {
      const week = [
        this.$t('weekAllName.sun'),
        this.$t('weekAllName.mon'),
        this.$t('weekAllName.tue'),
        this.$t('weekAllName.wed'),
        this.$t('weekAllName.thu'),
        this.$t('weekAllName.fri'),
        this.$t('weekAllName.sat'),
      ];
      return week[dayjs(str).day()];
    },
    stringTerminal(pTerminal) {
      return pTerminal !== 'N/A' ? `( ${this.$t('flight-only.terminal')} ${pTerminal} )` : '';
    },
  },
};
</script>
<style scoped>
img.return-air {
  transform: rotate(180deg);
}
.flight_box_baggage_body .flight_box_one_div .img_icon img {
  width: 50%;
  height: auto;
}
@media (max-width: 768px) {
  .flight_only_area .flight_only_body .flight_box_one .left_box .flight_icon {
    top: 0;
  }
  .flight_only_area .flight_only_body .flight_box_one .right_box .right_box_one .icon_derection {
    margin-top: -0.3rem;
  }
}
</style>
